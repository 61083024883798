import React from 'react';
import Menu from './Menu';
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from '@material-ui/icons/Close';
import DropDownMenu from './notification-menu';

class NavigationBar extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            menus: [],
            aliaskey: props['data-key'],
            toggleOpen: false,
            activeMenu:props.activeMenu,
            activePage:props.activePage,
        }
    }
    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadMenu();
        } catch (e) {
            console.log(e);
        }
    }
    componentDidUpdate(prevProps) {
       if(prevProps.activeMenu !== this.props.activeMenu){
           this.setState({activeMenu:this.props.activeMenu})
       }
       if(prevProps.activePage !== this.props.activePage){
        this.setState({activePage:this.props.activePage})
    }
    }
    loadMenu() {
        const menuUrl = `/api/contents/menu`;
        fetch(menuUrl)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({
                    menus: data[0].items
                })
            })
            .catch((e) => {
                console.log("error in fetching menu bar content", e)
            });
    }

    loadNotifications(){
        const notificationUrl = '/api/contents/alert-message-notification'
        fetch(notificationUrl)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({
                    menus: data[0].items
                })
            })
            .catch((e) => {
                console.log("error in fetching menu bar content", e)
            });
    
    }
    render() {
        return (

            <div className="navbar navbar-light navbar-static-top
                navbar-expand-md">
                <nav role="navigation" aria-label="Main Menu" className="navbar navbar-expand-sm navbar-light">
                    <div className="container-fluid">

                        <button onClick={() => this.setState({ toggleOpen: !this.state.toggleOpen })} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle
                            navigation">
                            {this.state.toggleOpen ? <CloseIcon className="menubar-button" /> : <MenuIcon className="menubar-button" />
                            }
                            
                        </button>
                        <div className={this.state.toggleOpen ? "collapse navbar-collapse show" : "collapse navbar-collapse"} id="navbarTogglerDemo03">
                            <ul className="navbar-nav mt-2 mt-lg-0">

                                {
                                    this.state.menus.map((menu, idx) => {
                                        const menumeta = {
                                            name: menu.name,
                                            url: menu.url,
                                            key: menu.key,
                                        };
                                        const allItems = [];

                                        menu.items.forEach((item) => {
                                            var myItem = {
                                                name: item.name,
                                                url: item.url,
                                                key: item.key,
                                                items: item.items,
                                                isOverview: false
                                            };
                                            if (item.footerlink.length > 0) {
                                                myItem = {
                                                    name: item.name,
                                                    url: item.url,
                                                    key: item.key,
                                                    items: item.footerlink,
                                                    isOverview: true
                                                };
                                                allItems.push(
                                                    myItem
                                                );
                                            }
                                            else {
                                                myItem = {
                                                    name: item.name,
                                                    url: item.url,
                                                    key: item.key,
                                                    items: item.items,
                                                    isOverview: false
                                                };
                                                allItems.push(
                                                    myItem
                                                );
                                            }
                                        });
                                       
                                        return (
                                            <Menu activePage={this.state.activePage} activeMenu={this.state.activeMenu} key={idx + menu.key} menu-meta={menumeta} menu-items={allItems} />
                                        )
                                    })
                                }

                            </ul>
                            
                        </div>
                        {/* <div><DropDownMenu/></div> */}
                    </div>
                </nav>
            </div>
        );
    };
}

export default NavigationBar;