import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableCell } from "@material-ui/core";
import CustomMuiTablePagination from "./custom-muitable-pagination";
import EsgFilters from "./esg-filters";
import { parseAsHtml } from "./util";
import greenTick from "../../public/images/green-tick.png"

class EsgTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: props.api,
      columns: [],
      data: [],
      rowsPerPage: 10,
      page: 0,
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.loadData = this.loadData.bind(this);
    this.filterData = this.filterData.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (this.props.api !== prevProps.api) {
      this.setState({ api: this.props.api }, () => {
        this.loadData(this.state.api);
      });
    }
  }
  componentDidMount() {
    try {
      if (this.state.api && this.state.api !== "") {
        this.loadData(this.state.api);
      }
    } catch (e) {
      console.log(e);
    }
  }
  loadData(api) {
    fetch(api)
      .then((resp) => resp.json())
      .then((data) => {
        this.setState({
          data: data.data,
          columns: data.columns,
          page: 0,
        });
      })
      .catch((e) => {
        console.log("error in loading table data", e);
      });
  }

  handleChangePage = (newPage) => {
    this.setState({
      page: newPage,
    });
  };
  handleChangeRowsPerPage = (val) => {
    this.setState({
      rowsPerPage: +val,
      page: 0,
    });
  };


  filterData(name, bondType, bondStandard) {
    fetch(this.state.api)
    .then((resp) => resp.json())
    .then((data)=>{
    if(name){
        let filterdData = data.data.filter((el) => {
                   return el.ISSUER === name 
        })
        this.setState({data: filterdData});
    }
    if(bondType){
      let filterdData = data.data.filter((el) => {
                 return el.BONDTYPE === bondType 
      })
      this.setState({data: filterdData});
  }
  
    if(name && bondType){
        let filterdData = data.data.filter((el) => {
                   return el.ISSUER === name &&
                    el.BONDTYPE === bondType
        })
        this.setState({data: filterdData});
    }
    if(name && bondType && bondStandard){
        let filterdData = data.data.filter((el) => {
                   return el.ISSUER === name &&
                    el.BONDTYPE === bondType &&
                    el.BONDSTANDARDS === bondStandard
        })
        this.setState({data: filterdData});
    }
        
    }).catch((e)=>{
        console.log("error in filtering data", e);
    });
  }

  render() {
    return (
      <div className="container-fluid">
          <div className="mb-2"><EsgFilters
          resetcallback={this.loadData}
          callback={this.filterData}
        /></div>
        <TableContainer>
          <Table className="table customize-table">
            <TableHead>
              <TableRow>
                {this.state.columns.map((col) => {
                  return (
                    <TableCell className="text-left" key={col} scope="col">
                      {col}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <tbody>
            {this.state.data.length > 0 ? (
                this.state.data
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((td, i) => {
                    let url = td["DATA_URL"]
                    console.log(url)
                    return (
                      <TableRow key={i}>
                        {Object.keys(td).map((item, j) => {
                          return item === "ISSUER" ? (
                            <TableCell key={`${j}${i}`} className="text-left">
                              <a href={url}>{(td[item])}</a>
                            </TableCell>
                          ) : (
                            <TableCell key={`${j}${j}`} className="text-left">
                             {item === "DATA_URL" ? null : parseAsHtml(td[item])}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell className="no-records" colSpan={8}>
                    No Records
                  </TableCell>
                </TableRow>
              )}
            </tbody>
            <CustomMuiTablePagination
              count={this.state.data.length}
              page={this.state.page}
              rowsPerPage={this.state.rowsPerPage}
              changeRowsPerPage={this.handleChangeRowsPerPage}
              changePage={this.handleChangePage}
            />
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default EsgTable;
