import React, { Component } from "react";
import TbgTable from "./components/tbg-table-aws";
//import env from '../config';;
// import moment from "moment";
import NavTabs from "./components/nav-tabs";
import Notes from "./components/notes";
import LinearProgress from "@material-ui/core/LinearProgress";

class BusinessGrowth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // api: `/api/tbg-new/all?`,
      notesApi: `/api/contents/notes?url=/resources-business-growth`,
      notesData: [],
      dataApi: `/api/tbg/yearly`,
      data: {},
      pageName: `businessGrowth`,
      columns: [],
      colsApi: "/api/tbg/columns",
      inProgress: true,
      timestamp: "",
      timestampApi: "/api/tbg/timestamp",
    };
  }

  componentDidMount() {
    try {
      this.loadColumns(this.state.colsApi);
      this.loadData(this.state.dataApi);
      this.loadNotes(this.state.notesApi);
      this.loadTimestamp(this.state.timestampApi);
    } catch (e) {
      console.log(e);
    }
  }

  loadNotes(api) {
    fetch(api)
      .then((resp) => resp.json())
      .then((cards) => {
        this.setState({
          notesData: cards.content.field_note_components,
        });
      })
      .catch((e) => {
        console.log("error in notes of business growth", e);
      });
  }

  loadData(api) {
    //console.log("business growth", api)

    fetch(api)
      .then((resp) => resp.json())
      .then((data) => {
        if (data.data) {
          // console.log(data.data)
          this.setState({
            data: data.data,

            //data.timestamp
          });
        }
      })
      .catch((err) => {
        console.log("error in loading business growth data", err);
      });
  }

  loadColumns(api) {
    fetch(api)
      .then((resp) => resp.json())
      .then((data) => {
        this.setState({
          columns: data.columns,
          inProgress: false,
        });
      })
      .catch((err) => {
        console.log("error in columns in business growth", err);
      });
  }

  loadTimestamp(api) {
    fetch(api)
      .then((resp) => resp.json())
      .then((data) => {
        let timestamp = "";
        Object.keys(data).forEach((item) => {
          timestamp = data[item];
        });
        this.setState({
          timestamp: timestamp,
        });
      })
      .catch((err) => {
        console.log("error in loading business growth timestamp", err);
      });
  }

  render() {
    return (
      <div className="container-fluid">
        {/* {Object.keys(this.state.data).length !== 0 ? */}
        <>
          <NavTabs
            tabs={[
              {
                title: "Equity",
                component: (
                  <>
                    {this.state.inProgress ? (
                      <LinearProgress />
                    ) : (
                      <TbgTable
                        timestamp={this.state.timestamp}
                        columns={this.state.columns.EQUITY || []}
                        data={this.state.data.EQUITY}
                        type={"EQUITY"}
                        pageName={this.state.pageName}
                      />
                    )}
                  </>
                ),
              },
              {
                title: "Currency",
                component: (
                  <>
                    {this.state.inProgress ? (
                      <LinearProgress />
                    ) : (
                      <TbgTable
                        timestamp={this.state.timestamp}
                        columns={this.state.columns.CURRENCY || []}
                        data={this.state.data.CURRENCY}
                        type={"CURRENCY"}
                        pageName={this.state.pageName}
                      />
                    )}
                  </>
                ),
              },
              {
                title: "Commodity",
                component: (
                  <>
                    {this.state.inProgress ? (
                      <LinearProgress />
                    ) : (
                      <TbgTable
                        timestamp={this.state.timestamp}
                        columns={this.state.columns.COMMODITY || []}
                        data={this.state.data.COMMODITY}
                        type={"COMMODITY"}
                        pageName={this.state.pageName}
                      />
                    )}
                  </>
                ),
              },

              {
                title: "Global Stocks",
                component: (
                  <>
                    {this.state.inProgress ? (
                      <LinearProgress />
                    ) : (
                      <TbgTable
                        timestamp={this.state.timestamp}
                        columns={this.state.columns.GLOBAL_STOCKS || []}
                        data={this.state.data.GLOBAL_STOCKS}
                        type={"GLOBAL_STOCKS"}
                        pageName={this.state.pageName}
                      />
                    )}
                  </>
                ),
              },
            ]}
          />
        </>
        {/* : ""} */}

        <div className="row mt-4">
          <div className="col-md-12">
            <Notes notesData={this.state.notesData} />
          </div>
        </div>
      </div>
    );
  }
}

export default BusinessGrowth;
