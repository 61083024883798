import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getDownloadIcon } from './util';
import { TableCell } from '@material-ui/core';
// import TablePagination from '@material-ui/core/TablePagination';
import CustomMuiTablePagination from './custom-muitable-pagination';


class AttachmentTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            api: props.api,
            columns: props.columns || [],
            data: props.data || [],
            rowsPerPage: 10,
            page: 0,
        };
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    };
    componentDidUpdate(prevProps) {
        if (this.props.api !== prevProps.api) {
            this.setState({ api: this.props.api }, () => {
                this.loadData(this.state.api);
            })
        }
        if (this.props.columns !== prevProps.columns) {
            this.setState({ columns: this.props.columns })
        }
        if (this.props.data !== prevProps.data) {
            this.setState({ data: this.props.data, page: 0, })
        }
    }
    componentDidMount() {
        try {
            if (this.state.api && this.state.api !== "") {
                this.loadData(this.state.api);
            }
        } catch (e) {
            console.log(e);
        }
    }
    loadData(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({
                    data: data.data,
                    columns: data.columns,
                    page: 0,
                });
            })
            .catch((e) => { console.log("error in loading table data", e) })

    }

    // handleChangePage = (event, newPage) => {
    //     this.setState({
    //         page: newPage,
    //     })
    // }
    // handleChangeRowsPerPage = (event) => {
    //     this.setState({
    //         rowsPerPage: +event.target.value,
    //         page: 0
    //     })
    // }
    
    handleChangePage = (newPage) => {
        this.setState({
            page: newPage,
        })
    }
    handleChangeRowsPerPage = (val) => {
        this.setState({
            rowsPerPage: +val,
            page: 0
        })
    }
    render() {

        return (


            // <div className="grid-table">
               
                <TableContainer>
                    <Table className="table customize-table">
                        <TableHead>

                            <TableRow>
                                {this.state.columns.map(col => {
                                    return <TableCell className="text-left" key={col} scope="col">
                                        {col}</TableCell>

                                })}
                            </TableRow>
                        </TableHead>
                        <tbody>
                            {this.state.data.length>0 ? this.state.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((td, i) => {
                                return <TableRow key={i}>
                                    {Object.keys(td).map((item, j) => {
                                        return (td[item].includes("http://") || td[item].includes("https://")) ?
                                            <TableCell key={`${j}${i}`} className="text-left" ><a target="_blank" rel="noopener noreferrer" href={td[item]}>{getDownloadIcon(td[item])}</a></TableCell> :
                                            <TableCell key={`${j}${j}`} className="text-left" >{td[item]}</TableCell>
                                    })}

                                </TableRow>
                            }):<TableRow><TableCell className="no-records" colSpan={this.props.pageType === "nseCorporateAction" ? 5 : 4}>No Records</TableCell></TableRow>}
                        </tbody>
                        <CustomMuiTablePagination
                                            count={this.state.data.length}
                                            page={this.state.page}
                                            rowsPerPage={this.state.rowsPerPage}
                                            changeRowsPerPage={this.handleChangeRowsPerPage}
                                            changePage={this.handleChangePage} />
                    </Table>
                    
                </TableContainer>
               
                /* <TablePagination
                    rowsPerPageOptions={[5, 10, 100]}
                    component="div"
                    count={this.state.data.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}

                /> */
            // </div>
        );
    }
}

export default AttachmentTable;