import React, { useState, useEffect } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import { Button } from "@material-ui/core";
import BellBadge from "./badge";
import NotificationContainer from "./notification-container";

export default function DropDownMenu() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [dTa, setdTa] = useState([]);
  const [TotalContents, setContents] = useState(0)

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await (
          await fetch("/api/contents/alert-message-notification")
        ).json();
        
        let dTa = [];
        const resp = await response.content.field_alert_component;
        resp.forEach(myFunction)
        
        function myFunction(item) {
          if (item.field_alert_display_status === "ON") {
            dTa.push(item);
          }
        }
        const Data = dTa.reverse().slice(0, 4);
       
        setdTa(Data);
        setContents(Data.length)
      } catch (e) {
        console.log("error in loading alert notification", e);
      }
    };
    fetchNotifications();
  }, []);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleClick = () => {
    // handle menu click here
    setOpen(false);
  };

  return (
    <div>
      <Button
        id= 'bell-button'
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <BellBadge contents={TotalContents} />
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal style={{zIndex:1}}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
              width: 350,
              height: "auto",
              borderRadius: 0,
              marginTop: '2%',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <div className="notify-Cont">
                    {dTa.map((item, idx) => {
                      return (
                        <div className="notmenucont">
                          <NotificationContainer data={item} />
                        </div>
                      );
                    })}
                  </div>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
