import React, { Component } from "react";
import "../../public/css/header-menu.scss";
import ifscwhitelogo from "../../public/images/ifsc-white-logo.png";
import GlobalSearch from "./global-search";
import Ticker from "./ticker";
import NavigationBar from "./menu-navigation-bar";
import { ReactComponent as HomeIcon } from "../../public/images/home.svg";
import { parseAsHtml } from "./util";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertApi: `/api/contents/alert`,
      breadcrumTitle: "",
      contentTitle: "",
      breadCrum: [],
      showBreadcrum: true,
      notfound: false,
      message: "",
      showAlert: false,
      activeMenu: "",
      breadCrumImg: [],
      screentop: "top",
      activePage: "",
    };
  }

  componentDidMount() {
    try {
      this._isMounted = true;
      //this.showalert(this.state.alertApi);

      //Scroll to top Code 
      window.addEventListener("scroll", () => {
        let screentop = "down";
        if (window.scrollY === 0) {
          screentop = "top";
        }
        this.setState({ screentop });
      });
    } catch (e) {
      console.log(e);
    }
  }

  //Set breadcrumb details of page
  updateTitle(breadcrumTitle, contentTitle, breadCrum, breadCrumImg) {
    breadcrumTitle = breadcrumTitle.trim();
    contentTitle = contentTitle.trim();
    this.setState({
      breadcrumTitle,
      contentTitle,
      breadCrum,
      breadCrumImg: breadCrumImg || [],
    });
  }

  //Show website alert from CMS based on Yes/No
  // showAlert() {
  //   fetch(this.state.alertApi)
  //     .then((resp) => resp.json())
  //     .then((response) => {
  //       this.setState({
  //         message: response.data[0],
  //         showAlert: removePtag(response.showAlert).toLowerCase().includes("yes")
  //           ? true
  //           : false,
  //       });
  //     })
  //     .catch((e) => {
  //       console.log("error in homepage alert", e);
  //     });
  // }

  //Hide breadcrum on Not Found Page
  hideBreadCrum() {
    this.setState({ showBreadcrum: false });
  }
  showHeaderWrapBackground() {
    this.setState({ notfound: true });
  }

  //Set Highlight Css on Active Menu
  activeMenu(menukey, pagekey) {
    this.setState({ activeMenu: menukey, activePage: pagekey || '' });
  }
  render() {
    return (
      <>

        {/* {this.state.showAlert ? (
          <div className="market-close-error-new">
            <h1>Welcome to NSE International Exchange</h1>
            {parseAsHtml(this.state.message)}</div>
        ) : (
          <></>
        )} */}
        <div
          style={
            this.state.breadCrumImg[0] !== undefined
              ? { backgroundImage: `url(${this.state.breadCrumImg[0].url})` }
              : { backgroundImage: `` }
          }
          className={
            this.state.showBreadcrum || this.state.notfound
              ? "header-wrap"
              : "header-home-wrap " + this.state.screentop
          }
        >


          {/* ) : (
          <></>
        )} */}
          {/* header */}
          <div role="banner">
            <div className="header-overlay pt-2">
              <div className="container-fluid">
                <div className="ifsc-logo-header row justify-content-between align-items-center pb-1 ">
                  {/* NSE Logo */}
                  <div className="col-lg-3 col-md-3 w-auto">
                    <div className="row">
                      <a href="/#">
                        <img
                          src={ifscwhitelogo}
                          className="logo"
                          alt="logo"
                          width="135"
                          height="85"
                        />
                      </a>
                    </div>
                  </div>
                  {/* Global Search Func */}
                  <div className="col-lg-6 col-md-6 w-auto">
                    <GlobalSearch />
                  </div>
                  {/* Ticker */}
                  <div className="col-lg-3 col-md-3 w-auto">
                    <Ticker />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Menubar */}
          <NavigationBar activePage={this.state.activePage} activeMenu={this.state.activeMenu} />
        </div>

        {/* Breadcrumb Section */}
        {this.state.showBreadcrum && (
          <section id="sectiontwo" style={
            this.state.breadCrumImg[0] !== undefined
              ? { backgroundImage: `url(${this.state.breadCrumImg[0].url})` }
              : { backgroundImage: `` }
          } role="navigation" aria-label="breadcrumb">
            <div className="col-lg-12 breadcrumb-row">


              <ol className="breadcrumb">

                <li className="breadcrumb-item">
                  <a href="/" title="Home">
                    <HomeIcon className="action" />
                    Home
                  </a>
                </li>
                {/* {
                        <li className="breadcrumb-item">{ JSON.stringify(this.state.breadCrum.length>0 && this.state.breadCrum[0]["field_breadcrumb"][0])}</li>
                         } */}
                {typeof this.state.breadCrum != undefined &&
                  this.state.breadCrum.length > 0 &&
                  typeof this.state.breadCrum[0]["field_breadcrumb"] != undefined &&
                  this.state.breadCrum[0]["field_breadcrumb"].length > 0 &&
                  this.state.breadCrum[0]["field_breadcrumb"].map((items, idx) => {
                    return (
                      <li key={idx} className="breadcrumb-item">
                        <a href={items.uri} title={items.title} aria-label={"Breadcrum url" + items.title}>{items.title}</a>
                      </li>
                    );
                  })}
              </ol>
              <h1>{parseAsHtml(this.state.breadcrumTitle)}</h1>
            </div>
          </section>
        )}

      </>
    );
  }
}

export default Header;
