import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import { Refresh, State3toggle } from "./custom-toolbar";
import {
  convertPrice,
  convertNumberPrecision,
  getToggleHeader,
  getToggleVal,
} from "./util";
import CSVDownloader from "./download-csv";
import Notes from "./notes";
// import { data } from "jquery";
// import DownloadExcel from './download-excel'

class MarketTable extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      api: props["data-api"],
      columns: [],
      data: [],
      latestIST: "",
      state3tog: "Millions",
      total: [],
      notesApi: props["notesApi"],
      mktNotes: [],
      pageName: props["pageName"],
    };

    this.refresh = this.refresh.bind(this);
    // this.toggleSwitch = this.toggleSwitch.bind(this);
    this.toggle3Switch = this.toggle3Switch.bind(this);
  }

  // toggleSwitch(checked) {
  //     this.setState({ milliontoggle: checked, data: this.state.data, })
  // }
  loadMktTurnoverNotes(api) {
    fetch(api)
      .then((resp) => resp.json())
      .then((cards) => {
        let mktNotes = [];
        let title = cards.content.field_note_components[0] || "";
        mktNotes.push(title, cards.content.field_note_components[1] || "");
        this.setState({ mktNotes: mktNotes });
      })
      .catch((e) => {
        console.log(`Error in loading ${this.state.pageName} notes`, e);
      });
  }

  toggle3Switch(checked) {
    this.setState({ state3tog: checked, data: this.state.data });
  }
  componentDidMount() {
    try {
      this._isMounted = true;
      if (this.state.api && this.state.api !== "") {
        this.loadData(this.state.api);
      }
      this.loadMktTurnoverNotes(this.state.notesApi);
    } catch (e) {
      console.log(e);
    }
  }
  componentDidUpdate(prevprops) {
    if (this.props["data-api"] !== prevprops["data-api"]) {
      this.setState({ api: this.props["data-api"] });
      this.loadData(this.props["data-api"]);
    }
  }
  loadData(api) {
    fetch(api)
      .then((resp) => resp.json())
      .then((data) => {
        this.setState({
          data: data.data,
          columns: data.columns,
          latestIST: data.timestampIST,
          total: data.total,
        });
      })
      .catch((e) => {
        console.log(`error in loading ${this.state.pageName} data`, e);
      });
  }
  refresh() {
    if (this._isMounted) {
      this.setState({ data: [], latestIST: "", total: [] });
      this.loadData(this.state.api);
    }
  }
  render() {
    const returnHeader = (colName, toggleState) => {
      if (colName === "Turnover (USD)" || colName === "Trade Value") {
        console.log(colName + " " + getToggleHeader(toggleState));
        return colName + " " + getToggleHeader(toggleState);
      }
      // if(colName === "Turnover (USD)" && toggleState === 'center'){
      //     return colName + " *($ Millions)";
      // }
      // else if (colName === "Turnover (USD)" && toggleState === 'right'){
      //     return colName + " *($ Billions)";
      // }
      // else if (colName === "Turnover (USD)" && toggleState === 'left'){
      //     return colName + " ($ Thousands)";
      // }
      else {
        return colName;
      }
    };
    const returnCellVal = (col, toggleState, cellVal) => {
      if (
        col.toLowerCase().includes("turnover") ||
        col.toLowerCase().includes("tradevalue")
      ) {
        return cellVal === "-"
          ? cellVal
          : convertNumberPrecision(getToggleVal(cellVal, toggleState));
      }
      // if(col === "Turnover" && toggleState === 'center'){
      //     return cellVal === '-' ? cellVal : convertNumberPrecision((cellVal) / 1000000);
      // }
      // else if(col === "Turnover" && toggleState === 'right'){
      //     return cellVal === '-' ? cellVal : convertNumberPrecision((cellVal) / 1000000000);
      // }
      // else if(col === "Turnover" && toggleState === 'left'){
      //     return cellVal === '-' ? cellVal : convertNumberPrecision((cellVal) / 1000);
      // }
      else {
        return convertPrice(cellVal);
      }
    };
    return (
      <>
        <div className="row">
          <div className="col-md-6 m-0 pt-2">
            {this.props.pageTitle === "" ? (
              <></>
            ) : (
              <h2 className="timestamp-heading">
                {this.props.pageTitle} {this.state.latestIST} IST
                <Refresh refreshcallback={this.refresh} />
              </h2>
            )}
          </div>

          <div className="col-md-6">
            <div
              className="mr-3 pb-3 align-items-center
                         row justify-content-end"
            >
              <div className="MuiButtonBase-root MuiIconButton-root MUIDataTableToolbar-icon-241">
              {/* <Toggle toggleState={this.state.milliontoggle} toggleCallback={this.toggleSwitch} /> */}
              {/* {this.state.pageName === "marketTurnover" ?  */}
              <>
                <State3toggle toggleCallback={this.toggle3Switch} defaultState={"Millions"} />
                <span>
                  <CSVDownloader
                    data={this.state.data}
                    csvname={this.state.pageName}
                  />
                </span>
                {this.state.pageName !== "NLTHistorical" ? (
                  <>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        this.state.pageName === "marketTurnover"
                          ? "/markets/turnover-business-growth"
                          : "/markets/historical-nlt"
                      }
                      className="ml-2 btn btn-orange pr-4 pl-4"
                    >
                      View All
                    </a>
                  </>
                ) : (
                  <></>
                )}
                {/* : <></>} */}
              </>
              </div>
            </div>
          </div>
        </div>
        <div
          className="table-responsive"
          id={
            this.state.pageName === "marketTurnover"
              ? "mktTurnoverResp"
              : this.state.pageName === "NLTHistorical"
              ? "nltHistoricalResp"
              : "nltResp"
          }
        >
          <Table
            className={
              this.state.pageName === "marketTurnover"
                ? "table lefthd-table"
                : "table lefthd-table nlt-tbl"
            }
            id="mktTurnover"
          >
            <TableHead>
              <tr className="border-right-days" key="days">
                {this.state.columns.map((col) => {
                  return col.label.columns ? (
                    <th
                      className="MuiTableCell-head"
                      colSpan={col.label.columns.length}
                      key={col.label.label}
                      scope="col"
                    >
                      {col.label.label}
                    </th>
                  ) : (
                    <th
                      className="MuiTableCell-head"
                      rowSpan="2"
                      key={col.label}
                      scope="col"
                    >
                      {col.label === "Trade Value"
                        ? returnHeader(col.label, this.state.state3tog)
                        : col.label}
                    </th>
                  );
                })}
              </tr>
              <tr className="border-right-qty" key="cols">
                {this.state.columns.map((col, idx) => {
                  return col.label.columns ? (
                    Object.keys(col.label.columns).map((subitem, colidx) => {
                      return (
                        <th
                          style={
                            col.label.columns[subitem] === "Turnover (USD)"
                              ? { width: `18%` }
                              : { width: `auto` }
                          }
                          className="MuiTableCell-head"
                          key={`${idx}${colidx}`}
                        >
                          {returnHeader(
                            col.label.columns[subitem],
                            this.state.state3tog
                          )}
                        </th>
                      );
                    })
                  ) : (
                    <React.Fragment key={`${idx}`}></React.Fragment>
                  );
                })}
              </tr>
            </TableHead>
            <tbody>
              {this.state.data <= 0 && this.props.pageTitle === "" ? (
                <>
                  <tr>
                    <td className="MuiTableCell-body" colSpan={9}>
                      <center>No Data Found</center>
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  {this.state.data.map((data, i) => {
                    return (
                      <React.Fragment key={`${i}`}>
                        <tr key={i}>
                          {Object.keys(data).map((value, j) => {
                            return (
                              <td
                                data-title={value}
                                className="MuiTableCell-body"
                                key={`${i}${j}`}
                              >
                                {returnCellVal(
                                  value,
                                  this.state.state3tog,
                                  data[value]
                                )}
                              </td>
                            );
                            // return j !== 0 ? <td className="MuiTableCell-body" key={`${i}${j}`} >
                            // {data[value]}</td>
                            // : <td className="MuiTableCell-body" key={`${i}${j}`} scope="col">
                            //     {data[value]}</td>
                          })}
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </>
              )}

              {this.props.pageTitle === "" ? (
                <></>
              ) : (
                <>
                  {this.state.total && this.state.total.length > 0 ? (
                    <React.Fragment>
                      <tr className="nlt-total-row">
                        <td colSpan={7} className="nlt-tbl-footer">
                          Total
                        </td>
                        <td className="totalval">
                          {convertNumberPrecision(
                            getToggleVal(
                              this.state.total[0].TOTALTRADEDVALUE,
                              this.state.state3tog
                            )
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </tbody>
          </Table>
          {this.state.pageName === ("NLT" || "NLTHistorical") ? (
            <p>*Includes SGX NLT/Block Volumes</p>
          ) : (
            <></>
          )}
        </div>
        {/* <div className="col-md-12"> */}
        <Notes notesData={this.state.mktNotes} />
        {/* </div> */}
      </>
    );
  }
}

export default MarketTable;
