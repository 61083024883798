import React, { useState,useEffect } from 'react'

function FlashButton(props) {

    console.log(props)
    const [settlementFile, setSettlementFile] = useState(null);
    const [indexedCombinedOIFile, setindexedCombinedOIFile] = useState(null);
    
    useEffect(()=>{
        setSettlementFile(props.settlementFile);
        setindexedCombinedOIFile(props.combinedOIFile);
    },[props])

    return (
<div className='row'>
            {/* <div className='col-lg-6 col-md-0 col-sm-0'></div> */}
            <div className='settlement-btns d-flex flex-row justify-content-center'>
                <a className='btn flash-buttons col-4' href={settlementFile?settlementFile[0].FILE_NAME:'#'}>
                    Daily Settlement Price INDEX
                    <div className='small text-align-center'>
                        Published on {settlementFile?settlementFile[0].FILE_DISPLAY_NAME:'#'}
                    </div>
                </a>
                <div className='col-1'></div>
                <a className='btn flash-buttons col-4' href={indexedCombinedOIFile?indexedCombinedOIFile[0].FILE_NAME:'#'} >
                    Index Combined OI
                    <div className='small text-align-center'>
                    Published on {indexedCombinedOIFile?indexedCombinedOIFile[0].FILE_DISPLAY_NAME:'#'}
                    </div>
                </a>
            </div>
        </div>    
)
}

export default FlashButton;
