import React from 'react';
import { getDownloadIcon, getTitleFileExtension } from './util';
import { sanitizeUrl } from './sanitize-url';
import Tooltip from '@material-ui/core/Tooltip';

class CircularCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            card: props.card,
            alignCard: props.alignCard,
            cardkey:props.cardkey,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.alignCard !== prevProps.alignCard) {
            this.setState({ alignCard: this.props.alignCard })
        }
        if (this.props.card !== prevProps.card) {
            this.setState({ card: this.props.card })
        }
    }

    render() {
        if (this.state.alignCard.toUpperCase() === "GRIDVIEW") {
            return (
                //grid View
                <div className="home-block-wrap">
                    <div className="cardspacing">
                        <h3>{this.state.card.TITLE || this.state.card.CATEGORY}</h3>
                        <div className="col-8 mx-0 px-0">
                            <button className="btn btn-secondary full-width">{this.state.card.DATE}</button>
                        </div>
                        <p>{this.state.card.CIRCULAR_NO || ""}</p>
                        <p className="grey-color circ-card-content" title={this.state.card.SUBJECT}>
                            {this.state.card.SUBJECT}</p>
                        <div className="d-flex download">
                            <div className="mr-2">
                            <Tooltip title={getTitleFileExtension(this.state.card.LINK) || this.state.card.SUBJECT} placement="right">
                            <a target="_blank" rel="noopener noreferrer" download="download" className="download-link"
                            aria-label={this.state.card.CIRCULAR_NO ? "Download Circular No. "+ this.state.card.CIRCULAR_NO:"Download "+this.state.card.SUBJECT} 
                            href={sanitizeUrl(this.state.card.LINK)}>{getDownloadIcon(this.state.card.LINK,(this.state.card.CIRCULAR_NO || this.state.cardkey))}Download</a>
                            </Tooltip>
                            </div>
                            {/* <div>
                                <p>Download</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            )
        }
        else if (this.state.alignCard.toUpperCase() === "LISTVIEW") {
            return (
                // list view
                <div className="listing-block-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-9">
                            <div className="listing-block listing-font align-txt">
                                <h6>{this.state.card.TITLE || this.state.card.CATEGORY}</h6>
                                <p>{this.state.card.CIRCULAR_NO || ""}</p>
                                <p className="grey-color">
                                    {this.state.card.SUBJECT}
                                </p>
                                <div className="d-flex mt-1 download">
                                    <div className="mr-2">
                                    <Tooltip title={getTitleFileExtension(this.state.card.LINK) || this.state.card.SUBJECT} placement="right">
                                    <a aria-label={this.state.card.CIRCULAR_NO?"Download Circular No "+ this.state.card.CIRCULAR_NO:"Download "+this.state.card.SUBJECT}
                                    target="_blank" rel="noopener noreferrer" download="download" className="download-link" href={sanitizeUrl(this.state.card.LINK)}>
                                        {getDownloadIcon(this.state.card.LINK,this.state.card.CIRCULAR_NO)}Download</a>
                                    </Tooltip>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 align-self-start">
                            {/* <div className="col-lg-10"> */}
                                <button className="btn btn-secondary full-width">{this.state.card.DATE}</button>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return ('')
        }
    }
}

export default CircularCard;