import React from 'react';
import '../../public/css/footer.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FooterContainer from './FooterContainer'
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import Scroll from 'react-scroll';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import SettingsIcon from '@material-ui/icons/Settings';
// import InfoIcon from '@material-ui/icons/Info';
import { ReactComponent as FormIcon } from '../../public/images/form-icon.svg';
import { ReactComponent as CircularFormIcon } from '../../public/images/circular-icon.svg';
import { BiPhoneCall } from "react-icons/bi";
// import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

var scroll = Scroll.animateScroll;

class Footer extends React.Component {
    _isMounted = false;
    _footerToFocus = React.createRef()
    constructor(props) {
        super(props);
        this.state = {
            addClass: true,
            menus: [],
            aliaskey: props['data-key'],
            drawerOpen: false,
            links: [],
        }
        this.onItemClick = this.onItemClick.bind(this);
    }

    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadMenu();
            this.loadFooterLinks();
        } catch (e) {
            console.log(e);
        }
    }
    loadMenu() {
        const menuUrl = `/api/contents/menu`;
        fetch(menuUrl)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({
                    menus: data[0].items
                })
            })
            .catch((e) => {
                console.log("error in loading footer content", e)
            });
    }

    /* Set Icons for Footer Links */
    getIcon = (linkName, id) => {
       if(linkName.toLowerCase().includes("circulars")) {
        return <CircularFormIcon id={id + "_icon"} />
       }
       else if(linkName.toLowerCase().includes("notif")) {
        return <InfoOutlinedIcon id={id + "_icon"} />
       }
       else if(linkName.toLowerCase().includes("holiday")) {
        return  <FormIcon id={id + "_icon"} />
       }
       else if(linkName.toLowerCase().includes("website")) {
        return  <FormIcon id={id + "_icon"} />
       }
       else if(linkName.toLowerCase().includes("contact")) {
        return  <BiPhoneCall id={id + "_icon"} />
       }
       else if(linkName.toLowerCase().includes("copyright")) {
        return  <FormIcon id={id + "_icon"} />
       }
       else {
           return ""
       }
    }
    loadFooterLinks() {
        const menuUrl = `/api/contents/footer-links`;
        fetch(menuUrl)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({
                    links: data[0].items
                })
            })
            .catch((e) => {
                console.log("error in fetching footer links content", e)
            });
    }

    /* Collapse/Expand Footer */
    onItemClick() {
        this.setState({ addClass: !this.state.addClass });
        if (this.state.addClass) {
            this.setState({ drawerOpen: true });
            scroll.scrollMore(300);
        }
        else {
            this.setState({ drawerOpen: false });
        }
    }

    render() {
        return (
            <footer className='container-fluid footerbg'> {/* adds the footer to a container and gives it the ifsc-blue background */}
                {/* Collapse/Expand Footer Icon */}
                <div id="Trigger" onClick={this.onItemClick} className="arrow-footer">
                    {this.state.addClass ? <ExpandLessIcon className="expandFooter" /> : <ExpandMoreIcon className="expandFooter" />}
                </div>

                 {/* On expand - footer sitemap is displayed */}
                <Collapse
                    className="shadow-border-top"
                    in={this.state.drawerOpen}
                    direction='up'
                    ref={this._footerToFocus}
                >
                    <Paper elevation={0}>
                        <div className="footer-container container-fluid">
                            <h3>Sitemap</h3>
                            <div className="row">
                                {
                                    this.state.menus.map((menu, idx) => {
                                        const menumeta = {
                                            name: menu.name,
                                            url: menu.url,
                                            key: menu.key,
                                        };
                                        const allItems = [];
                                        menu.items.forEach((item) => {
                                            allItems.push(
                                                {
                                                    name: item.name,
                                                    url: item.url,
                                                    key: item.key,
                                                    items: item.items
                                                }
                                            );
                                        });
                                        return (
                                            <FooterContainer key={idx + menu.key} menu-meta={menumeta} menu-items={allItems} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </Paper>
                </Collapse>

                {/* Footer Quick Links */}
                <div className="footerbg col-lg-12">
                    <div className="row">
                        <div className="col-lg-7 col-md-5">
                            <ul className="footerstrip">
                                {this.state.links.map((link, idx) => {
                                    return idx < 6 ? <li key={link.key}>

                                        {/* Get Icons for links */}
                                        {this.getIcon(link.name, link.key)}
                                        
                                        <a aria-label={link.name + " Quick link"}
                                            href={link.url} title={link.name}>
                                            {link.name}</a>
                                    </li> : ""
                                })}
                            </ul>
                        </div>
                        <div className="col-lg-5 col-md-7">
                            <div className="d-flex align-items-center height100 justify-content-end">
                            {this.state.links.map((link, idx) => {

                                    return link.name.includes("Copyright ©") ? 
                                        //copyright section
                                        <p key={link.key} className="white_p">{link.name}.</p> : ""
                            })}
                            
                                <ul className="footerstrip">
                                {this.state.links.map((link, idx) => {
                                    return idx > 6 ? 
                                        <li key={link.key}>
                                        <a href={link.url} title={link.name}>
                                        {link.name}
                                        {link.name.includes("Terms") ? <span className="ml-2">|</span> : ""}
                                        </a></li> 
                                        : ""
                                })}
                                
                                </ul>
                            </div>

                        </div>
                        
                    </div>
                </div>
                
            </footer>
        );
    }
}

export default Footer;