import React from 'react';
import NavTabs from './components/nav-tabs';
import moment from "moment";
//import env from '../config';;
import ReportTab from './components/report-tab';
import OtherReportTab from './components/otherReportTab';

class Reports extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        try {
            this._isMounted = true;
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return <div className="container-fluid">
        <NavTabs tabs={[
            { title: "Current Reports", component: <ReportTab tabkey="daily" dataapi={`/api/daily-reports`} /> },
            // { title: "Monthly Reports", component: <ReportTab tabkey="monthly" dataapi={`/api/monthly-reports`} /> },
            { title: "Historical Reports", component: <ReportTab tabkey="historical" dataapi={`/api/historical-reports?date=`+moment(new Date()).format("DD-MMM-YYYY")} /> },
{ title: "Contract Reports", component: <ReportTab tabkey="contract-files" dataapi={`/api/contract-files`} /> },
            { title: "Stream Info Reports", component: <ReportTab tabkey="contract-files" dataapi={`/api/streamer-info-files`} /> },           
 { title: "Other Reports", component: <OtherReportTab dataapi={`/api/contents/page?url=/markets/reports/otherreports`} /> }
            
        ]} />
        </div>
    }
}

export default Reports;
