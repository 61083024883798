import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { State3toggle, State4toggle } from "./custom-toolbar";
// import CSVDownloader from "./download-csv";
import { convertPrice, convertNumberPrecision, getToggleHeader } from "./util";
// import LinearProgress from '@material-ui/core/LinearProgress'
import * as XLSX from "xlsx";
import { ReactComponent as CsvIcon } from "../../public/images/csv-icon.svg";

class TbgTable extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {
      timestamp: props.timestamp,
      timestampAPi: `/api/tbg/getTimestamp`,
      columns: props.columns || [],
      currentTime: "Yearly",
      state3tog: "Thousands",
      state3togGS: "Actual",
      records: false,
      csvdata: [],
      inProgress: false,
      data: props.data,
      pageName: props.pageName,
      // type: props.type,
      tab: props.type,
      currentYears: "",
      currentMonth: "",
    };
    console.log(props);

    this.dateRedirect = this.dateRedirect.bind(this);
    this.dailyData = this.dailyData.bind(this);
    this.monthlyData = this.monthlyData.bind(this);
    this.yearlyData = this.yearlyData.bind(this);
    this.timeLink = this.timeLink.bind(this);
    this.toggle3Switch = this.toggle3Switch.bind(this);
    this.toggle3SwitchGS = this.toggle3SwitchGS.bind(this);
    this.exportFile = this.exportFile.bind(this);
  }

  exportFile() {
    let filename = "business-growth";
    let wb = XLSX.utils.table_to_book(
      document.getElementById("businessGrowthTbl"),
      { raw: true, blankrows: false }
    );
    XLSX.writeFile(wb, filename + ".xls");
    return false;
  }

  changeColumnNames = () => {
    let columns = this.state.columns;
    switch (this.state.currentTime) {
      case "Yearly":
        columns[0].label = "Year";
        break;
      case "Monthly":
        columns[0].label = "Month";
        break;
      case "Daily":
        columns[0].label = "Date";
        break;
      default:
        columns[0].label = "Year";
        break;
    }
    this.setState({
      columns,
    });
  };

  toggle3Switch(checked) {
    this.setState({ state3tog: checked });
  }
  toggle3SwitchGS(checked) {
    this.setState({ state3togGS: checked });
  }
  componentDidMount() {
    try {
      //this.loadTimestamp(this.state.timestampAPi);
      console.log(this.state.pageName);
      this.changeColumnNames();
      this.setCsvData(this.state.data);
    } catch (e) {
      console.log(e);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.columns !== prevProps.columns) {
      this.setState({
        columns: this.props.columns,
        inProgress: false,
      });
    }
    if (this.props.data !== prevProps.data) {
      this.setState(
        {
          data: this.props.data,
          records: true,
        },
        () => { }
      );
      this.setCsvData(this.props.data);
    }
    if (this.props.timestamp !== prevProps.timestamp) {
      this.setState({
        timestamp: this.props.timestamp,
      });
    }
  }
  dateRedirect = (param) => {
    switch (this.state.currentTime) {
      case "Monthly":
        this.dailyData(param);
        break;
      case "Yearly":
        this.monthlyData(param);
        break;
      default:
        break;
    }
  };

  dailyData = (month) => {
    this.setState({ currentTime: "Daily", currentMonth: month }, () => {
      this.changeColumnNames();
    });
    const dailyApi = `/api/tbg/daily?month=${month}`;
    this.loadData(dailyApi);
  };

  monthlyData = (years) => {
    this.setState({ currentTime: "Monthly", currentYears: years }, () => {
      this.changeColumnNames();
    });
    const monthlyApi = `/api/tbg/monthly?years=${years}`;
    this.loadData(monthlyApi);
  };

  yearlyData = () => {
    this.setState({ currentTime: "Yearly" }, () => {
      this.changeColumnNames();
    });
    const yearlyApi = `/api/tbg/yearly`;
    this.loadData(yearlyApi);
  };
  loadTimestamp(api) {
    fetch(api)
      .then((resp) => resp.json())
      .then((data) => {
        this.setState({ timestamp: data.timestamp });
      })
      .catch((e) => {
        console.log("error in business growth timestamp", e);
      });
  }
  loadData(api) {
    //this.setState({inProgress : true});
    fetch(api)
      .then((resp) => resp.json())
      .then((data) => {
        //this.setState({inProgress : false});
        if (Object.keys(data.data).length !== 0) {
          this.setState({
            data: data.data[this.state.tab],
            records: true,
          });
          this.setCsvData(data.data[this.state.tab]);
        } else {
          this.setState({
            data: [],
            records: false,
          });
        }
      })

      .catch((e) => {
        this.setState({ inProgress: false, records: false, data: [] });
        this.setCsvData([]);
        console.log("error in loading business growth data", e);
      });
  }

  setCsvData = (data) => {
    if (data) {
      const sample = data;
      const csvdata = Object.keys(sample).map((item, id) => sample[item]);
      this.setState({ csvdata });
    }
  };

  // Link to be displayed above table
  timeLink = () => {
    if (this.state.columns[0]) {
      switch (this.state.columns[0].label) {
        case "Date":
          return (
            <button
              className="navigate-table"
              onClick={() => this.monthlyData(this.state.currentYears)}
            >
              <NavigateBeforeIcon />
              Monthly
            </button>
          );
        case "Month":
          return (
            <button
              className="navigate-table"
              onClick={() => this.yearlyData()}
            >
              <NavigateBeforeIcon />
              Yearly
            </button>
          );
        default:
          break;
      }
    }
  };
  render() {
    const cols = this.state.columns;
    const tableBody = this.state.data;
    // console.log(this.props.type);
    /* New Hardcoded Code Starts*/
    const getValues = (rowdata) => {
      let cells = [];
      switch (this.state.tab) {
        case "EQUITY":
          cells.push(
            <>
              <td className="tbg-cell text-right" data-title={"FUTIDX Volume"}>
                {checkVal("Volume", rowdata["FUTIDX Volume"])}
              </td>
              <td
                className="tbg-cell text-right"
                data-title={
                  "FUTIDX Value" + getToggleHeader(this.state.state3tog)
                }
              >
                {checkVal("Value", rowdata["FUTIDX Value"])}
              </td>
              <td className="tbg-cell text-right" data-title={"OPTIDX Volume"}>
                {checkVal("Volume", rowdata["OPTIDX Volume"])}
              </td>
              <td
                className="tbg-cell text-right"
                data-title={
                  "OPTIDX Value" + getToggleHeader(this.state.state3tog)
                }
              >
                {checkVal("Value", rowdata["OPTIDX Value"])}
              </td>
              <td className="tbg-cell text-right" data-title={"FUTSTK Volume"}>
                {checkVal("Volume", rowdata["FUTSTK Volume"])}
              </td>
              <td
                className="tbg-cell text-right"
                data-title={
                  "FUTSTK Value" + getToggleHeader(this.state.state3tog)
                }
              >
                {checkVal("Value", rowdata["FUTSTK Value"])}
              </td>
              <td className="tbg-cell text-right" data-title={"OPTSTK Volume"}>
                {checkVal("Volume", rowdata["OPTSTK Volume"])}
              </td>
              <td
                className="tbg-cell text-right"
                data-title={
                  "OPTSTK Value" + getToggleHeader(this.state.state3tog)
                }
              >
                {checkVal("Value", rowdata["OPTSTK Value"])}
              </td>
            </>
          );
          break;
        case "COMMODITY":
          cells.push(
            <>
              <td className="tbg-cell text-right" data-title={"FUTCOM Volume"}>
                {checkVal("Volume", rowdata["FUTCOM Volume"])}
              </td>
              <td
                className="tbg-cell text-right"
                data-title={
                  "FUTCOM Value" + getToggleHeader(this.state.state3tog)
                }
              >
                {checkVal("Value", rowdata["FUTCOM Value"])}
              </td>
            </>
          );
          break;
        case "CURRENCY":
          cells.push(
            <>
              <td className="tbg-cell text-right" data-title={"FUTCUR Volume"}>
                {checkVal("Volume", rowdata["FUTCUR Volume"])}
              </td>
              <td
                className="tbg-cell text-right"
                data-title={
                  "FUTCUR Value" + getToggleHeader(this.state.state3tog)
                }
              >
                {checkVal("Value", rowdata["FUTCUR Value"])}
              </td>
              <td className="tbg-cell text-right" data-title={"OPTCUR Volume"}>
                {checkVal("Volume", rowdata["OPTCUR Volume"])}
              </td>
              <td
                className="tbg-cell text-right"
                data-title={
                  "OPTCUR Value" + getToggleHeader(this.state.state3tog)
                }
              >
                {checkVal("Value", rowdata["OPTCUR Value"])}
              </td>
            </>
          );
          break;

        case "GLOBAL_STOCKS":
          cells.push(
            <>
              <td className="tbg-cell text-right" data-title={"USAIDR Volume"}>
                {checkVal("Volume", rowdata["USAIDR Volume"])}
              </td>
              <td
                className="tbg-cell text-right"
                data-title={
                  "USAIDR Value" + getToggleHeader(this.state.state3togGS)
                }
              >
                {checkVal("Value", rowdata["USAIDR Value"])}
              </td>
            </>
          );
          break;

        default:
          break;
      }
      if (this.props.type === "GLOBAL_STOCKS") {
        cells.push(
          <>
            <td className="tbg-cell text-right" data-title={"Total Volume"}>
              {checkVal("Volume", rowdata["Total Volume"])}
            </td>
            <td
              className="tbg-cell text-right"
              data-title={
                "Total Value" + getToggleHeader(this.state.state3togGS)
              }
            >
              {checkVal("Value", rowdata["Total Value"])}
            </td>
          </>
        );
      } else {
        cells.push(
          <>
            <td className="tbg-cell text-right" data-title={"Total Volume"}>
              {checkVal("Volume", rowdata["Total Volume"])}
            </td>
            <td
              className="tbg-cell text-right"
              data-title={"Total Value" + getToggleHeader(this.state.state3tog)}
            >
              {checkVal("Value", rowdata["Total Value"])}
            </td>
          </>
        );
      }

      return cells;
    };

    const checkVal = (col, val) => {
      if (val) {
        if (this.props.type === "GLOBAL_STOCKS") {
          return returnCellVal(col, this.state.state3togGS, val);
        } else {
          return returnCellVal(col, this.state.state3tog, val);
        }
      } else {
        return 0.0;
      }
    };

    /* New Hardcoded Code Ends*/
    const returnCellVal = (col, toggleState, cellVal) => {
      if (this.props.type === "GLOBAL_STOCKS") {
        if (col.includes("Value") && toggleState === "Actual") {
          return convertNumberPrecision(Number(cellVal));
        }
        if (col.includes("Value") && toggleState === "Thousands") {
          return convertNumberPrecision(Number(cellVal) / 1000);
        } else if (col.includes("Value") && toggleState === "Millions") {
          return convertNumberPrecision(Number(cellVal) / 1000000);
        } else if (col.includes("Value") && toggleState === "Billions") {
          return convertNumberPrecision(Number(cellVal) / 1000000);
        } else if (col.includes("Volume")) {
          return convertPrice(Number(cellVal));
        } else {
          return cellVal;
        }
      }
      if (col.includes("Value") && toggleState === "Millions") {
        return convertNumberPrecision(Number(cellVal));
      } else if (col.includes("Value") && toggleState === "Billions") {
        return convertNumberPrecision(Number(cellVal) / 1000);
      } else if (col.includes("Value") && toggleState === "Thousands") {
        return convertNumberPrecision(Number(cellVal) * 1000);
      } else if (col.includes("Volume")) {
        return convertPrice(Number(cellVal));
      } else {
        return cellVal;
      }
    };

    let datecol = cols[0] ? cols[0].label : "Year";
    return (
      <>
        <div className="row">
          <div className="col-md-6 m-0">
            <div className="ml-3 mt-3 mb-3 row">
              <h3 className="timestamp-heading">
                As on {this.state.timestamp}
              </h3>
            </div>
          </div>

          <div className="col-md-6">
            <div style={{display:"flex"}}
              className={
                this.props.type === "GLOBAL_STOCKS"
                  ? "mr-2 mt-2 mb-2 row justify-content-center align-items-center"
                  : "mr-3 mt-3 mb-3 justify-content-center align-items-center"
              }
            >
              {this.props.type === "GLOBAL_STOCKS" ? (
                <State4toggle
                  toggleCallback={this.toggle3SwitchGS}
                  defaultState="Actual"
                  pageName={this.state.pageName}
                />
              ) : (
                <State3toggle
                  toggleCallback={this.toggle3Switch}
                  defaultState="Thousands"
                  pageName={this.state.pageName}
                />
              )}
              {/* <DownloadIconButton /> */}
              {this.state.pageName === "businessGrowth" ? <span><button
                onClick={() => {
                  this.exportFile();
                }}
                className="btn btn-white btn-csv-download d-flex align-items-center col-lg-12"
               // style={{
               // maxWidth:"20%"
               // }}
              >
                <CsvIcon className="icon-color mr-1" />
                Download (.CSV)
              </button></span> : <button
                onClick={() => {
                  this.exportFile();
                }}
                className="btn btn-white btn-csv-download d-flex align-items-center col-lg-12"
              >
                <CsvIcon className="icon-color mr-1" />
                Download (.CSV)
              </button>}

              {/* <span><CSVDownloader data={this.state.csvdata} csvname={"business-growth"} /></span> */}
            </div>
          </div>
        </div>
        {/* {this.state.inProgress ? <LinearProgress/> : <> */}
        <div className="col-md-12 tbgtbl">
          {this.timeLink()}
          <div className="table-responsive tbgtable" id="responsiveTable">
            <Table id="businessGrowthTbl" className="table lefthd-table">
              <TableHead>
                <TableRow key="time">
                  {cols.map((item, idx) => {
                    return item.label.columns ? (
                      <th
                        className="MuiTableCell-head"
                        key={`${idx}${item.label.label}`}
                        colSpan="2"
                        scope="col"
                      >
                        {item.label.label}
                      </th>
                    ) : (
                      <th
                        className="MuiTableCell-head"
                        key={`${idx}${item.label}`}
                        rowSpan="2"
                        scope="col"
                      >
                        {item.label}
                      </th>
                    );
                  })}
                </TableRow>
                <TableRow key="data">
                  {cols.map((item, idx) => {
                    return item.label.columns ? (
                      Object.keys(item.label.columns).map((subitem, colidx) => {
                        return (
                          <th
                            className={"MuiTableCell-head w-9 text-right"}
                            key={`${idx}${colidx}`}
                            scope="col"
                          >
                            {item.label.columns[subitem]}
                            <br />
                            {item.label.columns[subitem].includes("Val")
                              ? this.props.type === "GLOBAL_STOCKS"
                                ? getToggleHeader(this.state.state3togGS)
                                : getToggleHeader(this.state.state3tog)
                              : ""}
                          </th>
                        );
                      })
                    ) : (
                      <React.Fragment key={`${idx}`}></React.Fragment>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.records === true ||
                  (tableBody && Object.keys(tableBody).length > 0) ? (
                  Object.keys(tableBody).map((row, idx) => {
                    return (
                      <React.Fragment key={row}>
                        <TableRow>
                          {/* Old Loop (Dynamic) Code Starts*/}
                          {/* {Object.keys(tableBody[row]).map((cell, jdx) => {
                                                    return <React.Fragment key={jdx}>
                                                        <td data-title={cell === "date" ? datecol : (cell.includes("Value") ? cell + getToggleHeader(this.state.state3tog) : cell)} className={"tbg-cell " + (jdx === 0 ? "text-left" : "text-right")} key={`${jdx}${idx}`}>
                                                            {datecol !== "Date" && jdx === 0 ?
                                                                <button className="tbg-time-link" onClick={() => this.dateRedirect(tableBody[row][cell])}>{tableBody[row][cell]}</button>
                                                                : returnCellVal(cell, this.state.state3tog, tableBody[row][cell])
                                                            }
                                                        </td>
                                                    </React.Fragment>
                                                })} */}
                          {/* Old Loop (Dynamic) Code Ends*/}

                          {/* New Hardcoded Code */}
                          <td
                            data-title={datecol}
                            className={"tbg-cell text-left"}
                            key="datecol"
                          >
                            {datecol !== "Date" ? (
                              <button
                                className="tbg-time-link"
                                onClick={() =>
                                  this.dateRedirect(tableBody[row].date)
                                }
                              >
                                {tableBody[row].date}
                              </button>
                            ) : (
                              returnCellVal(
                                "date",
                                this.state.state3tog,
                                tableBody[row].date
                              )
                            )}
                          </td>
                          {getValues(tableBody[row])}
                          {/* New Hardcoded Code Ends*/}
                        </TableRow>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={12}>No Records</td>
                  </tr>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </>
    );
  }
}

export default TbgTable;
