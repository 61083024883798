import React, { useEffect, useState } from "react";
import { getDownloadIcon } from "./util";

function OtherReportTab(props) {
  const [data, setData] = useState();
  const cmsApi = props.dataapi;

  useEffect(() => {
    fetch(cmsApi)
      .then((res) => res.json())
      .then(
        (response) => setData(response.content.field_components),
        console.log(data)
      )
      .catch((err) => console.error(err));
  }, []);

  return (
    <div class="container">

      <div class="table-responsive">

        <table className='MuiTable-root table customize-table'>

          <thead>

            <tr>

              <th style={{ fontWeight: 'bold' }}>Month</th>

              <th style={{ fontWeight: 'bold' }}>Mode of Trading Data</th>

            </tr>

          </thead>

          {data && (
            data.map((val, key) => {

              let text = val.field_text;
              let result = text.split("-");

              return <tr>

                <td>{result[0]}</td>


                <td>
                  <div className="col-md-12 mt-2 mb-2">
                    <a
                      className="download-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={val.file_url}
                      title={val.field_text}

                    >
                      {getDownloadIcon(
                        val.file_url,
                        val.file_url.substring(
                          val.file_url.lastIndexOf("/") + 1
                        )
                      )}
                      {val.field_text}
                    </a>
                  </div>

                </td>

              </tr>

            }))}

        </table>

      </div>

    </div>
  );

}

export default OtherReportTab;
