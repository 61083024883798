import React, { Component } from "react";
import { Select, MenuItem } from "@material-ui/core";
//import env from '../../config';

const ButtonHeight = {
  height: "100%",
};

class EsgFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api1: `/api/esg-bonds-dynamic`,
      api2: '/api/esg-bonds-dynamic',
      selectName: [],
      selectBondType: [],
      selectBondStandard: [],
      issuerNames: "",
      bondTypes: "",
      bondStandards: "",
      callback: props.callback,
      resetcallback: props.resetcallback,
    };
    this.filter = this.filter.bind(this);
    this.reset = this.reset.bind(this);
    this.loadFilters = this.loadFilters.bind(this);
  }
  componentDidMount() {
    try {
        this.loadFilters(this.state.api1)
      this._isMounted = true;
    } catch (e) {
      console.log(e);
    }
  }

  loadFilters(api) {
    fetch(api)
        .then(resp => resp.json())
        .then((resp) => {
            this.setState({ selectName: resp.issuerNames, selectBondType:resp.bondTypes, selectBondStandard: resp.bondStandards });
        })
        .catch((e) => { console.log("error in loading list of filters in esg bonds", e) })
}

  filter() {
    console.log("Issuer Names:",this.state.issuerNames);
    if (this.state.issuerNames || this.state.bondTypes || this.state.bondStandards) {
      this.state.callback(this.state.issuerNames, this.state.bondTypes, this.state.bondStandards);
    } else {
    }
  }
  reset() {
    this.setState({
      issuerNames: "",
      bondTypes: "",
      bondStandards: "",
    });
    this.state.resetcallback(this.state.api1);
  }

  render() {
    return (
      <>
        <div className="mr-5 row corporate-filter-fields">
          <div className="col-md-3 pr-0 cofilter-padding">
            <Select
              className="customize-input-group quotes-select-filter"
              onChange={(e) => {
                this.setState({ issuerNames: e.target.value });
              }}
              value={this.state.issuerNames}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Issuer
              </MenuItem>
              {console.log("this.state.selectName",this.state.selectName)}
              {this.state.selectName.map((x, idx) => {
                return (
                  <MenuItem key={x + idx} value={x}>
                    {console.log("x",x)}
                    {x}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="col-md-3 pr-0 cofilter-padding">
            <Select
              className="customize-input-group quotes-select-filter"
              onChange={(e) => {
                this.setState({ bondTypes: e.target.value });
              }}
              value={this.state.bondTypes}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Bond Type
              </MenuItem>
              {this.state.selectBondType.map((x, idx) => {
                return (
                  <MenuItem key={x + idx} value={x}>
                    {x}
                  </MenuItem>
                );
              })}
            </Select>
          </div>

          <div className="col-md-3 pr-0 cofilter-padding">
            <Select
              className="customize-input-group quotes-select-filter"
              onChange={(e) => {
                this.setState({ bondStandards: e.target.value });
              }}
              value={this.state.bondStandards}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Bond Standard
              </MenuItem>
              {this.state.selectBondStandard.map((x, idx) => {
                return (
                  <MenuItem key={x + idx} value={x}>
                    {x}
                  </MenuItem>
                );
              })}
            </Select>
          </div>

          <span className="red cofilter-validation-to">
            {this.state.validationTo}
          </span>
          <div className="col-md-1 pr-0 cofilter-padding">
            <button
              className="btn esg-btn full-width cofilter-btn"
              style={ButtonHeight}
              onClick={this.filter}
            >
              Go
            </button>
          </div>
          <div className="col-md-1 pr-0 cofilter-padding">
                <button className="btn btn-primary full-width cofilter-btn" style={ButtonHeight} onClick={this.reset}>Reset</button>
          </div>
        </div>
      </>
    );
  }
}
export default EsgFilters;
