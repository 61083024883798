import React from 'react';
import EsgTable from './components/esg-table';

class EsgBonds extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            headerRef: props.headerRef,
            api: '/api/esg-bonds-dynamic',
        };
    }

    componentDidMount() {
        try {
            this._isMounted = true;
           

        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return (
            <div id="esgBonds"className="container-fluid">
                <h3 className="mt-2 ">Find all ESG Bonds displayed on NSE IFSC </h3>
                <div className="row">
                    <div className="col-md-12 mt-2">
                        <EsgTable api={this.state.api} />
                    </div>
                </div>
            </div>
        );
    }
}

export default EsgBonds;