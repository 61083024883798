import React, { useEffect, useState } from "react";
import { getDownloadIcon } from "../views/components/util";

function LatencyStatistics(props) {
  const [data, setData] = useState();

  const style = {
    height:'35px',
    width:'35px'
  }

  return (
    <>
        <div>
            <a
            className="download-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.nseix.com/api/content/latency_statistics/IFSC_Latency_stats.csv"

          >
            <img src="https://www.nseix.com/nseixcms/sites/default/files/2023-06/xls.png" style={style}></img>Daily Latency
            
          </a>
        </div>
    </>
  );
}

export default LatencyStatistics;

