import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { withStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import { ReactComponent as DownloadIconImage } from "../../public/images/csv-icon.svg";
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
// import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
// import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
// import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import Grow from "@material-ui/core/Grow";
// import TextField from '@material-ui/core/TextField';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: `45px`,
    height: `23px`,
    padding: 0,
    display: `flex`,
    //borderRadius: `30%`,
  },
  switchBase: {
    padding: "4px 2px",
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      right: 0,
      "& + $track": {
        opacity: 1,
        border: `none`,
        // backgroundColor: '#e86e25',
        // borderColor: '#e86e25',
      },
    },
  },
  thumb: {
    width: 15,
    height: 15,
    boxShadow: "none",
    marginRight: `5px`,
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: `30px`,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

// const AntSwitch = withStyles((theme) => ({
//     root: {
//         width: 40,
//         height: 20,
//         padding: 0,
//         display: 'flex',
//         marginTop: ' 6%',
//         borderRadius: '30%',
//     },
//     switchBase: {
//         padding: '4px 2px',
//         color: theme.palette.grey[500],
//         '&$checked': {
//             transform: 'translateX(12px)',
//             pzcolor: theme.palette.common.white,
//             right: 0,
//             '& + $track': {
//                 opacity: 1,
//                 backgroundColor: '#e86e25',
//                 borderColor: '#e86e25',
//             },
//         },
//     },
//     thumb: {
//         width: 12,
//         height: 12,
//         boxShadow: 'none',
//     },
//     track: {
//         border: `1px solid ${theme.palette.grey[500]}`,
//         borderRadius: 16 / 2,
//         opacity: 1,
//         backgroundColor: theme.palette.common.white,
//     },
//     checked: {},
// }))(Switch);

const defaultToolbarStyles = {
  iconButton: {},
};
export const ViewColumnIcon = () => {
  return (
    <>
      <SettingsIcon className="customize-icon" />
      <p className="toolbar-labels">Customize</p>
    </>
  );
};
export const DownloadIcon = () => {
  return <DownloadIconImage className="downloadcsv-icon" />;
};
export const DownloadIconButton = () => {
  return (
    <div className="btn btn-white btn-csv-download d-flex align-items-center">
      <DownloadIconImage className="downloadcsv-icon mr-1" />
      Download (.CSV)
    </div>
  );
};
export const Refresh = ({ refreshcallback }) => {
  return (
    <IconButton
      aria-label="refresh"
      className={"refresh-button"}
      onClick={() => {
        refreshcallback();
      }}
    >
      <AutorenewIcon className={"circle-icon"} />
      <span className="hide">Refresh</span>
    </IconButton>
  );
};
// export const Toggle = ({ toggleCallback }) => {
//     const [toggleCheck, settoggleCheck] = useState(true);
//     return <><AntSwitch onClick={(event) => { settoggleCheck(!toggleCheck); toggleCallback(event.target.checked); }} checked={toggleCheck} name="checkedC" />
//         <p className="mt-3 toolbar-labels">1 Million</p>
//         </>
// }
export const Toggle = ({ toggleCallback }) => {
  const [toggleCheck, settoggleCheck] = useState(true);
  return (
    <>
      <Typography component="div" className="typography-toggle">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>
            <AntSwitch
              label="Primary"
              className="toggle-switch"
              onClick={(event) => {
                settoggleCheck(!toggleCheck);
                toggleCallback(event.target.checked);
              }}
              checked={toggleCheck}
              name="checkedC"
            />
          </Grid>
          <Grid className="million-label" item>
            Million
          </Grid>
        </Grid>
      </Typography>
    </>
  );
};

// export const State3toggle = ({ toggleCallback }) => {
//     const [alignment, setAlignment] = useState('center');
//     const handleAlignment = (event, newAlignment) => {
//         if (newAlignment === null) {
//             return
//         }
//         setAlignment(newAlignment);
//         toggleCallback(newAlignment);
//     };
//     return <div>
//         <ToggleButtonGroup
//             value={alignment}
//             exclusive
//             onChange={handleAlignment}
//             aria-label="text alignment"
//             size="small"
//         >
//             <ToggleButton value="left" size="small" aria-label="left aligned" className="left">
//                 < FiberManualRecordIcon />
//             </ToggleButton>
//             <ToggleButton value="center" size="small" aria-label="centered" className="middle">
//                 < FiberManualRecordIcon />
//             </ToggleButton>
//             <ToggleButton value="right" size="small" aria-label="right aligned">
//                 < FiberManualRecordIcon />
//             </ToggleButton>
//         </ToggleButtonGroup>
//         {alignment === 'left' ? <div className="toggle-label">Thousands</div> : alignment === 'center' ? <div className="toggle-label mr-4">Millions</div> : <div className="toggle-label margin">Billions</div>}
//     </div>
// }

export const State4toggle = ({ toggleCallback, defaultState, pageName }) => {
  const [alignment, setAlignment] = useState(defaultState);
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment === null) {
      return;
    }
    setAlignment(newAlignment);
    toggleCallback(newAlignment);
  };

  return (
    pageName === "businessGrowth" ?
      <div className="toggle col-md-7" style={{ width: "51%" }}>
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
          size="small"
        >
          <ToggleButton
            value="Actual"
            size="small"
            aria-label="left aligned"
            className="left"
          >
            <RadioButtonCheckedIcon />
            Actual Value
          </ToggleButton>
          <ToggleButton
            value="Thousands"
            size="small"
            aria-label="left aligned"
            className="left"
          >
            <RadioButtonCheckedIcon />
            Thousands
          </ToggleButton>
          <ToggleButton
            value="Millions"
            size="small"
            aria-label="centered"
            className="middle"
          >
            <RadioButtonCheckedIcon />
            Millions
          </ToggleButton>
          <ToggleButton value="Billions" size="small" aria-label="right aligned">
            <RadioButtonCheckedIcon />
            Billions
          </ToggleButton>
        </ToggleButtonGroup>
      </div> : <div className="toggle">
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
          size="small"
        >
          <ToggleButton
            value="Actual"
            size="small"
            aria-label="left aligned"
            className="left"
          >
            <RadioButtonCheckedIcon />
            Actual Value
          </ToggleButton>
          <ToggleButton
            value="Thousands"
            size="small"
            aria-label="left aligned"
            className="left"
          >
            <RadioButtonCheckedIcon />
            Thousands
          </ToggleButton>
          <ToggleButton
            value="Millions"
            size="small"
            aria-label="centered"
            className="middle"
          >
            <RadioButtonCheckedIcon />
            Millions
          </ToggleButton>
          <ToggleButton value="Billions" size="small" aria-label="right aligned">
            <RadioButtonCheckedIcon />
            Billions
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
  );
};


export const State3toggle = ({ toggleCallback, defaultState, pageName }) => {
  const [alignment, setAlignment] = useState(defaultState);
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment === null) {
      return;
    }
    setAlignment(newAlignment);
    toggleCallback(newAlignment);
  };
  return (
    pageName === "businessGrowth" ?
      <div className="toggle" >
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
          size="small"
        >
          <ToggleButton
            value="Thousands"
            size="small"
            aria-label="left aligned"
            className="left"
          >
            <RadioButtonCheckedIcon />
            Thousands
          </ToggleButton>
          <ToggleButton
            value="Millions"
            size="small"
            aria-label="centered"
            className="middle"
          >
            <RadioButtonCheckedIcon />
            Millions
          </ToggleButton>
          <ToggleButton value="Billions" size="small" aria-label="right aligned">
            <RadioButtonCheckedIcon />
            Billions
          </ToggleButton>
        </ToggleButtonGroup>
      </div> : <div className="toggle" >
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
          size="small"
        >
          <ToggleButton
            value="Thousands"
            size="small"
            aria-label="left aligned"
            className="left"
          >
            <RadioButtonCheckedIcon />
            Thousands
          </ToggleButton>
          <ToggleButton
            value="Millions"
            size="small"
            aria-label="centered"
            className="middle"
          >
            <RadioButtonCheckedIcon />
            Millions
          </ToggleButton>
          <ToggleButton value="Billions" size="small" aria-label="right aligned">
            <RadioButtonCheckedIcon />
            Billions
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
  );
};
export const State3toggleGS = ({ toggleCallback }) => {
  const [alignment, setAlignment] = useState("Millions");
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment === null) {
      return;
    }
    setAlignment(newAlignment);
    toggleCallback(newAlignment);
  };
  return (
    <div className="toggle">
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
        size="small"
      >
        <ToggleButton
          value="Thousands"
          size="small"
          aria-label="left aligned"
          className="left"
        >
          <RadioButtonCheckedIcon />
          Thousands
        </ToggleButton>
        <ToggleButton
          value="Millions"
          size="small"
          aria-label="centered"
          className="middle"
        >
          <RadioButtonCheckedIcon />
          Millions
        </ToggleButton>
        <ToggleButton value="Billions" size="small" aria-label="right aligned">
          <RadioButtonCheckedIcon />
          Billions
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export const UsMarkettoggle = ({ tickerCallback }) => {
  const [tickerData, setTickerData] = useState("derivatives");
  const handleTickerData = (event, newTickerData) => {
    if (newTickerData === null) {
      return;
    }
    setTickerData(newTickerData);
    tickerCallback(newTickerData);
  };
  return (
    <div className="toggle">
      <ToggleButtonGroup
        value={tickerData}
        exclusive
        onChange={handleTickerData}
        aria-label="text alignment"
        size="small"
      >
        <ToggleButton
          value="depository"
          size="small"
          aria-label="left aligned"
          className="left"
        >
          <RadioButtonCheckedIcon />
          Depository
        </ToggleButton>
        <ToggleButton
          value="derivatives"
          size="small"
          aria-label="centered"
          className="middle"
        >
          <RadioButtonCheckedIcon />
          Derivatives
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export const CustomSearchBar = (props) => {
  const { searchText, onSearch } = props;
  const handleTextChange = (event) => {
    onSearch(event.target.value);
  };

  return (
    <Grow appear in={true} timeout={300}>
      <div className="w-35 input-group colorsearchbar">
        <input
          type="text"
          className="form-control"
          placeholder="Filter Company Name or Symbol"
          value={searchText || ""}
          onChange={handleTextChange}
        />
        <div className="input-group-append">
          <span className="searchgroupicon">
            <i className="fa fa-search" aria-hidden="true"></i>
          </span>
        </div>
      </div>
    </Grow>
  );
};

class CustomToolbar extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(
  CustomToolbar
);
