import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import { parseAsHtml } from './util';

class CustomTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            api: props["data-api"],
            columns: props["data-columns"] || [],
            data: props["data-rows"] || [],
            urlTable: props.urlTable,
            contentTitle: props.tableid,
        }
        this.getQuotesLink = this.getQuotesLink.bind(this);
    };

    componentDidMount() {
        try {
            if (this.state.api && this.state.api !== "") {
                this.loadData(this.state.api);
            }
        } catch (e) {
            console.log(e);
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps["data-rows"] !== this.props["data-rows"]) {
            this.setState({ data: this.props["data-rows"] });
        }
    }
    loadData(api) {

        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                data.api = api
                this.setState(data);
            })
            .catch((e) => { console.log("error in custom table", e) })
    }
    createSortHandler(e) {

    }
    getQuotesLink(symbol) {
        if (symbol.includes("NIFTY")) {
            return "/get-quotes/derivatives?symbol=" + symbol;
        }
        else if (symbol.includes("USD")) {
            return "/currency-getquote?symbol=" + symbol;
        }
        else if (symbol === "GOLD" || symbol === "SILVER") {
            return "/commodity-getquote?symbol=" + symbol;
        }
        else {
            return "/get-quotes/equity?symbol=" + symbol;
        }
    }
    render() {
        return (
            <div className="col-md-12 m-0">
                <div className="pdt-10">
                    <div className="float-left live_watch">{this.state.title}</div>
                    {/* <div className="float-right session">Session 1 Market Open - As on Mar 23,2020 11:15 IST
                      <span><img src="images/refresh.png" alt="refresh" /></span>
                    </div> */}
                    <div className="clearfix"></div>
                </div>
                <div className="table-responsive">
                    <TableContainer>
                        <Table id={this.state.contentTitle || ""} className="table customize-table">
                            <TableHead>
                                <tr>
                                    {
                                        this.state.columns.map((e, idx) => {
                                            return <th className="MuiTableCell-head preline-span" key={`${idx}${e}`} scope="col">{e}</th>
                                        })
                                    }
                                </tr>
                            </TableHead>
                            <tbody>
                                {this.state.data.length > 0 ?
                                    /* For table cells with link - urlTable attribute set to true - for setting links on symbols for redirecting to Get Quotes Page */
                                    this.state.urlTable === true ?
                                        this.state.data.map((data, i) => {
                                            return <tr key={i}>
                                                <td><a href={this.getQuotesLink(data[0])}>{data[0]}</a></td>
                                                <td>{data[1]}{data[1].includes("NIFTY") ? " Index" : ""}</td>
                                            </tr>
                                        })
                                        :
                                        this.state.data.map((data, i) => {
                                            return <tr key={i}>
                                                {data.map((value, j) => {
                                                    return value === '-' ?
                                                        <td
                                                            className="MuiTableCell-body text-center"
                                                            key={`${i}${j}`}>
                                                            {parseAsHtml(value)}
                                                            {/* {value.includes(".svg") ? <img src={value} width={"16px"} height={"16px"} /> : value.includes("http") ? <a href={value}>download</a> : value} */}
                                                        </td> :
                                                        <td
                                                            className={value.includes("img") ? "MuiTableCell-body text-center" : "MuiTableCell-body text-align-left"}
                                                            key={`${i}${j}`}>
                                                            {parseAsHtml(value)}
                                                            {/* {value.includes(".svg") ? <img src={value} width={"16px"} height={"16px"} /> : value.includes("http") ? <a href={value}>download</a> : value} */}
                                                        </td>
                                                })
                                                }
                                            </tr>
                                        }) : <tr className="MuiTableCell-body text-center"><td className="MuiTableCell-body text-center" colSpan={this.state.columns.length}>No records</td></tr>
                                }
                            </tbody>
                        </Table>
                    </TableContainer>
                    {this.props.tableid === 'clearingholiday' ? <div>Note: For the latest settlement holidays, kindly refer to the monthly settlement calendars issued by NICCL.</div> : ''}
                </div>
            </div>
        );
    }
}

export default CustomTable;