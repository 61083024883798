import React, { Component } from 'react';
import moment from "moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Select, MenuItem } from "@material-ui/core";




const ButtonHeight = {
    height: '87%',
}
const dateFormat = "DD-MMM-YYYY";
class HistoricalNltFilter extends Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
        this.state = {
            todate: moment().utc().utcOffset("+05:30").format(dateFormat),
            fromdate: moment(new Date()).subtract(1, 'days').format(dateFormat),
            // todate: null,
            // fromdate: null,
            linksarray: [
                {
                    id: "1D",
                },
                {
                    id: "1W",
                },
                {
                    id: "1M",
                },
                {
                    id: "3M",
                },
                {
                    id: "6M",
                },
                {
                    id: "1Y",
                },
            ],
            activeLink: "1D",
            fromDateOpen: false,
            toDateOpen: false,
            callback: props.callback,
            api1: `/api/nse-corporate-announcement`,
            instrument: '',
            selectInstrument: [],
            symbol: '',
            selectSymbolIdx: [],
            resetcallback: props.resetcallback,
            maxFromDate: moment().utc().utcOffset("+05:30").format(dateFormat),
            validationfrom: '',

        }
        this.handleClick = this.handleClick.bind(this);
        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
        this.filter = this.filter.bind(this);
        this.reset = this.reset.bind(this);
        this.validateFromToDate = this.validateFromToDate.bind(this);
        // this.state.callback(
        //     this.state.instrument,
        //     this.state.symbol,
        //     this.state.expiry,
        //     this.state.optionType,
        //     this.state.strikeprice,
        //     moment(this.state.fromdate,dateFormat).format(dateFormat),
        //     moment(this.state.todate,dateFormat).format(dateFormat)
        // );
    };

    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadFilters(this.state.api1)
        } catch (e) {
            console.log(e);
        }

    }

    loadFilters(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((resp) => {
                // console.log(resp.companyNames);
                this.setState({
                    selectInstrument: resp.companyNames,
                    selectSymbolIdx: resp.subjectTypes
                })
            })
    }

    handleClick(id) {
        this.setState({
            activeLink: id,
            todate: moment().utc().utcOffset("+05:30").format(dateFormat),
        }, function () {
            this.setState({
                maxFromDate: moment().utc().utcOffset("+05:30").format(dateFormat),
            })
            switch (id) {
                case "1D":
                    this.setState({ fromdate: moment(new Date()).subtract(1, 'days').format(dateFormat) });
                    break;
                case "1W":
                    this.setState({ fromdate: moment(new Date()).subtract(7, 'days').format(dateFormat) });
                    break;
                case "1M":
                    this.setState({ fromdate: moment(new Date()).subtract(1, 'months').format(dateFormat) });
                    break;
                case "3M":
                    this.setState({ fromdate: moment(new Date()).subtract(3, 'months').format(dateFormat) });
                    break;
                case "6M":
                    this.setState({ fromdate: moment(new Date()).subtract(6, 'months').format(dateFormat) });
                    break;
                case "1Y":
                    this.setState({ fromdate: moment(new Date()).subtract(1, 'year').format(dateFormat) });
                    break;
                default:
                    break;
            }
        })
    }

    handleFromDateChange = (date) => {
        this.setState({
            fromdate: moment(date, dateFormat).format(dateFormat),
            fromDateOpen: false
        }, () => {
            this.validateFromToDate();
        });

    }
    validateFromToDate = () => {
        if (moment(this.state.fromdate, dateFormat) > moment(this.state.todate, dateFormat)) {
            this.setState({ validationfrom: "From date should not be after to date" })
        }
        else {
            this.setState({ validationfrom: "" })
        }
    }
    handleToDateChange = (date) => {
        this.setState({
            todate: moment(date, dateFormat).format(dateFormat),
            toDateOpen: false
        }, () => {
            this.validateFromToDate();
            this.setState({ maxFromDate: this.state.todate })
        });
    }


    filter() {
        // if (this.state.instrument === '') {
        //     alert('Please Select Instrument');
        //     return;
        // }
        // if (this.state.symbol === '') {
        //     alert(' Please Select Instrument');
        //     return;
        // }
        if (this.state.todate === '' || this.state.todate === null) {
            alert('Please Select To Date');
            return;
        }
        if (this.state.fromdate === '' || this.state.fromdate === null) {
            alert('Please Select From Date');
            return;
        }
        

        //    if (this.state.instrument || this.state.symbol || this.state.expiry || this.state.optionType || (this.state.fromdate && this.state.todate && moment(this.state.todate) >= moment(this.state.fromdate))) {

        if (this.state.validationfrom === "") {
            this.state.callback(
                this.state.instrument,
                this.state.symbol,
                moment(this.state.fromdate, dateFormat).format(dateFormat),
                moment(this.state.todate, dateFormat).format(dateFormat)
            );
        }



    }

    reset() {
        this.loadFilters(this.state.api1);
        this.setState({
            validationfrom: "",
            symbol: '',
            instrument: '',
            todate: null,
            fromdate: null,
            activeLink: "",
            maxFromDate: moment().utc().utcOffset("+05:30").format(dateFormat),
        }, () => {
            this.state.resetcallback();
        })


    }

    selectInstType = (instrument) => {
        let type = ""
        if (instrument.includes('Com')) {
            type = "COM";
            //this.loadSymbols(this.state.api1 + '/symbols?type=COM');
        } else if (instrument.includes('Cur')) {
            type = "CUR";
            //this.loadSymbols(this.state.api1 + '/symbols?type=CUR');
        } else if (instrument.includes('Stock')) {
            type = "STK";
            //this.loadSymbols(this.state.api1 + '/symbols?type=STK');
        } else {
            type = "IDX";
            //this.loadSymbols(this.state.api1 + '/symbols?type=IDX');
        }
        this.loadFilters(this.state.api1 + '?symbols=' + type);
        this.setState({ loadInstType: instrument });
    }


    render() {


        return (
            <div className="container-fluid">
                <div className="row ml-2 historical-filters">
                    <div className="form-group historical-filter-inputs col-lg-1 col-md-1 col-sm-2">
                        <Select className="customize-input-group quotes-select-filter"
                            onChange={(e) => {
                                this.setState({
                                    instrument: e.target.value, yearSelected: null, expiry: '', selectExpiry: [],
                                    selectSymbolIdx: [],
                                    // symbol: '',
                                    optionType: '',
                                    strikepriceList: [], strikeprice: ''
                                });
                                this.selectInstType(e.target.value);
                                // let abc = e.target.value;
                                // if (abc.includes('Com')) {
                                //     this.loadSymbols(this.state.api1 + '/symbols?type=COM');
                                // } else if(abc.includes('Cur')) {
                                //     this.loadSymbols(this.state.api1 + '/symbols?type=CUR');
                                // } else if(abc.includes('Stock')) {
                                //     this.loadSymbols(this.state.api1 + '/symbols?type=STK');
                                // }else {
                                //     this.loadSymbols(this.state.api1 + '/symbols?type=IDX');
                                // }
                            }} value={this.state.instrument}
                            displayEmpty>
                            <MenuItem value="" disabled>Select Issuer</MenuItem>
                            {this.state.selectInstrument.map((x, idx) => {
                                return <MenuItem key={x + idx} value={x}>
                                    {x}
                                </MenuItem>
                            })}
                        </Select>

                    </div>

                    <div className="form-group historical-filter-inputs col-lg-1 col-md-1 col-sm-2">
                        <Select className="customize-input-group quotes-select-filter"
                            onChange={(e) => {
                                this.setState({
                                    symbol: e.target.value, yearSelected: null, expiry: '',
                                    selectExpiry: [], optionType: '',
                                    strikepriceList: [], strikeprice: ''
                                });
                            }} value={this.state.symbol}
                            displayEmpty>

                            <MenuItem value="" disabled>Select Subject</MenuItem>

                            {this.state.selectSymbolIdx.map((x, idx) => {
                                return <MenuItem key={x + idx} value={x}>
                                    {x}
                                </MenuItem>
                            })}
                        </Select>
                    </div>




                    {/* <div className="form-group historical-filter-inputs">
                        <div className="customize-input-group quotes-select-filter">
                            <input type="text" className="historical-input-form-control" placeholder="Enter Strike Price"

                                disabled={!this.state.instrument.includes('Options')}
                                onChange={(e) => { this.setState({ strikeprice: e.target.value }) }} value={this.state.strikeprice}

                            />
                        </div>
                    </div> */}
                </div>
                <div className="col-12 form-inline col-lg-12 d-flex justify-content-start">
                    <div className="historical-dates filterdata form-group mr-2 pt-2 pb-2">
                        {this.state.linksarray.map((links, index) => {
                            return <div key={index} data-val={links.id} onClick={() => this.handleClick(links.id)} className={(links.id === this.state.activeLink ? "days active" : "days")}>
                                {links.id}</div>
                        })}
                    </div>
                    {/* search inputs*/}
                    {/* <div className="col-lg-3">
                        <div className="customize-input-group" style={InputBoxHeight}>
                            <div className="input-group-prepend">
                                <Horizontalinput />
                            </div>
                            <input type="text" className="input-form-control" />
                            <div className="input-group-append">
                                <SearchIcon />
                            </div>
                        </div>
                    </div> */}
                    {/* 2 */}
                    <div className="row historical-inputs justify-content-start pt-2">
                        <div className="col form-group mr-2 historical-date-input respWidth">
                            <div className="customize-input-group">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker variant="inline" format="dd-MMM-yyyy" views={["year", "month", "date"]} openTo="year"
                                        margin="normal" id="fromDate"
                                        value={this.state.fromdate} maxDate={this.state.maxFromDate} onChange={this.handleFromDateChange}
                                        autoOk={true}
                                        KeyboardButtonProps={{

                                            "aria-label": "from_date_button"
                                        }}
                                        placeholder="From"
                                        disableFuture={true}
                                        helperText={
                                            this.state.validationfrom !== "" ?
                                                this.state.validationfrom : null
                                        }
                                    // KeyboardButtonProps={{
                                    //     onFocus: (e) => {
                                    //         this.setState({
                                    //             fromDateOpen: true
                                    //         });
                                    //     }
                                    // }}
                                    // PopoverProps={{
                                    //     disableRestoreFocus: true,
                                    //     onClose: () => {
                                    //         this.setState({
                                    //             fromDateOpen: false
                                    //         });
                                    //     }
                                    // }}
                                    // InputProps={{
                                    //     onFocus: () => {
                                    //         this.setState({
                                    //             fromDateOpen: true
                                    //         });
                                    //     }
                                    // }}
                                    // open={this.state.fromDateOpen} 
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        {/* 3 */}
                        <div className="col form-group mr-2 historical-date-input respWidth">
                            <div className="customize-input-group">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker variant="inline" format="dd-MMM-yyyy" margin="normal" views={["year", "month", "date"]} openTo="year"
                                        id="toDate" maxDate={new Date()} value={this.state.todate} onChange={this.handleToDateChange}
                                        autoOk={true} disableFuture={true}
                                        KeyboardButtonProps={{
                                            "aria-label": "to_date_button"
                                        }}
                                        placeholder="To"
                                    // KeyboardButtonProps={{
                                    //     onFocus: (e) => {
                                    //         this.setState({
                                    //             toDateOpen: true
                                    //         });
                                    //     }
                                    // }}
                                    // PopoverProps={{
                                    //     disableRestoreFocus: true,
                                    //     onClose: () => {
                                    //         this.setState({
                                    //             toDateOpen: false
                                    //         });
                                    //     }
                                    // }}
                                    // InputProps={{
                                    //     onFocus: () => {
                                    //         this.setState({
                                    //             toDateOpen: true
                                    //         });
                                    //     }
                                    // }}
                                    // open={this.state.toDateOpen} 
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        {/* 4 */}
                        <div className="col form-group mr-2 historical-date-input">
                            <button className="btn btn-orange full-width" style={ButtonHeight} onClick={this.filter}>Go</button>
                        </div>
                        <div className="col form-group mr-2 historical-date-input">
                            <button className="btn btn-orange full-width" style={ButtonHeight} onClick={this.reset}>Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default HistoricalNltFilter;