import React from 'react';
//import env from '../config';;
import NavTabs from './components/nav-tabs';
import AccordianDataTable from './components/accordian-data-table';
// import PriceInfoContainer from './components/price-info-container';
import Notes from './components/notes';
import moment from 'moment';
import HistDataTable from './components/historical-data-table';
import { validate } from '@material-ui/pickers';

class GetQuotes extends React.Component {
    constructor(props) {
        super(props);
        console.log("props",props)
        const queryparams = new URLSearchParams(props?.location?.search);
        console.log(" props?.location?_______", props?.location)
        const pageType = props?.location?.pathname.includes('commodity') ? 'commodity' : props.location.pathname.includes('currency') ? 'currency' : 'equity';
        this.state = {
            queryparams,
            headerRef: props.headerRef,
            //api: `/api/getquotes?symbol=${queryparams.get("symbol") || 'NIFTY'}`,
            api: `/api/getquotes` + props.location.search,
            symbol: `${queryparams.get("symbol") || '-'}`,
            data: [],
            timestmp: '',
            notesApi: `/api/contents/notes?url=/get-quotes`,
            notesData: [],
            recentTabs: [],
            localStorageAcess: props.location.pathname,
            pageURL: props.location,
            abcd: localStorage.getItem(pageType),
            typeOfSymbol: pageType,
            histApi: ``,
            fromdate: moment(new Date()).subtract(1, 'months').format("DD-MMM-YYYY"),
            todate: moment().utc().utcOffset("+05:30").format('DD-MMM-YYYY'),
            // fromdate: '26-APR-2015',
            // todate: '27-APR-2025',
            //histInst:queryparams.get("inst") ? (queryparams.get("inst").substr(queryparams.get("inst").indexOf(" ") + 1).substr(0, 3).concat(queryparams.get("inst").substr(0, 3).replace("Ind", "IDX").replace("Sto", "STK")).toUpperCase()) : '',
            histInst:queryparams.get("inst") ? queryparams.get("inst") : '',
            histSym: `${queryparams.get("symbol") || '-'}`,
            histStkpr: queryparams.get("strikeprice") ? queryparams.get("strikeprice").replace('-', '0') : '',
            histOptTyp: queryparams.get("optiontype") ? queryparams.get("optiontype").replace('-', 'XX') : '',
            histExpiry: queryparams.get("expiry")  ?  queryparams.get("expiry") : '',
            filename:"Historical Data"
        }
    }

    containsSpecialChars(str) {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return specialChars.test(str);
    }

    componentDidMount() {
        try {
            //console.log('@@@', this.state.localStorageAcess);
            this._isMounted = true;
            this.state.headerRef.current.updateTitle("Get Quotes", "Get Quotes", [{
                "field_breadcrumb": [
                    { "uri": "/markets/derivatives-watch", "title": "Markets" },
                    { "uri": "/markets/derivatives-watch", "title": "Live Watch" },
                    { "uri": "/get-quotes/:type", "title": "Get Quotes" },]
            }]);
            if(this.containsSpecialChars(this.state.symbol) == true){
                this.setState({
                    symbol:'-'
                })
            }

            this.loadQuotes(this.state.api);
            this.loadNotes(this.state.notesApi);
            this.setState({
                histApi: `/api/historical-derivative?fromdate=${this.state.fromdate}&todate=${this.state.todate}&expiry=${this.state.histExpiry}&strikeprice=${this.state.histStkpr}&optiontype=${this.state.histOptTyp}&symbol=${this.state.histSym}&instrumenttype=${this.state.histInst}`,
                filename:'Historical_Data_'+this.state.histInst+'_'+this.state.histSym+'_'
                +moment(this.state.fromdate, "DD-MMM-YYYY").format("DD-MM-YYYY")+'_'
                +moment(this.state.todate, "DD-MMM-YYYY").format("DD-MM-YYYY")
            }
            )
            //start logic for nav tabs
            if (this.state.abcd === null) {
                var temp = [];
                temp.push({
                    title: this.state.symbol,
                    redirectUrl: this.state.pageURL.pathname + this.state.pageURL.search,
                    time: new Date()
                });
                var d = JSON.stringify(temp);

                localStorage.setItem(this.state.typeOfSymbol, d);
            } else {

                

                var recover = JSON.parse(this.state.abcd);
                if (recover.length > 0) {
                    let reverse = [];
                    if (recover[recover.length - 1].title === this.state.symbol) {
                        for (let i = recover.length - 1; i >= 0; i--) {
                            if (recover[i].title === this.state.symbol) {
                                continue;
                            }
                            reverse.push(recover[i]);
                        }
                        reverse.sort(function (a, b) {
                            if (a.time < b.time) return 1;
                            if (a.time > b.time) return -1;
                            return 0;
                        });
                        this.setState({ recentTabs: reverse })
                    }
                    else {
                        let reverse = [];
                       // var isRemoved = false;
                        for (let i = recover.length - 1; i >= 0; i--) {
                            if (recover[i].title === this.state.symbol) {
                             //   isRemoved = true;
                                continue;
                            }
                            reverse.push(recover[i]);
                        }
                        reverse.sort(function (a, b) {
                            if (a.time < b.time) return 1;
                            if (a.time > b.time) return -1;
                            return 0;
                        });
                        if (reverse.length > 4) {
                            reverse.pop();
                        }
                        this.setState({ recentTabs: reverse })

                        //if (isRemoved == true) 
                        //{
                            recover = [];
                            recover = JSON.parse(JSON.stringify(reverse));
                            //if true then add
                            recover.push({
                                title: this.containsSpecialChars(this.state.symbol)?"-":this.state.symbol,
                                redirectUrl: this.state.pageURL.pathname + this.state.pageURL.search,
                                time: new Date()
                            });
                        //}
                        recover.sort(function (a, b) {
                            if (a.time < b.time) return -1;
                            if (a.time > b.time) return 1;
                            return 0;
                        });
                        if (recover.length > 5) {

                            recover.shift();
                        }
                        let d = JSON.stringify(recover);
                        localStorage.setItem(this.state.typeOfSymbol, d);
                    }
                }
            }
            //end logic for nav tabs
        } catch (e) {
            console.log(e);
        }
    }
    

 loadQuotes(api) {


        fetch(api)
            .then(resp => resp.json())
            .then((response) => {
                if( response.data == "Symbol should have only alphabets"){

                    this.setState({ symbol:'-'});
                }
                const data = [];
                data.push(
                    response.data[0].SYMBOL_DESC,
                    response.data[0].LASTPRICE,
                    response.data[0].DAYCHANGE,
                    response.data[0].PERCHANGE)
                this.setState({
                    timestmp: response.data[0].TIMESTMP,
                    data: data,  
                })
            })
            .catch((e) => { console.log("error in loading get quotes data", e) })
    }
    loadNotes(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((cards) => {
                this.setState({
                    notesData: cards.content.field_note_components,
                })
            })
            .catch((e) => { console.log("error in notes in get quotes", e) })
    }

    render() {
        return (
            <div className="container-fluid mt-0 static_content">
                <div className="row">
                    <div className="col-md-12">
                        <NavTabs color="secondary" tabs={[
                            
                            {
                                title: this.state.symbol, 
                                component:
                                    <>
                                        {/* <PriceInfoContainer timestmp={this.state.timestmp} data={this.state.data} columns={["Symbol", "Underlying Info", "VOLUME FREEZE QUANTITY", "CHANGE"]} /> */}
                                        <div className="container-fluid">
                                            <div className="row mt-3">
                                                <div className="col-md-12">
                                                    <h2 className="get-quotes">Contract Information</h2>
                                                </div>
                                            </div>
                                            {/* <div className="row mt-3">
                                                <div className="col-md-12"> */}
                                            <NavTabs color="primary"
                                                tabs={[{
                                                    title: "All Contracts", component:
                                                        // <div className="container-fluid nav-tabs-box">
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <p className="mt-3 mb-2">Filter By</p>
                                                                    <AccordianDataTable data-api={this.state.api} symbol={this.state.symbol} />

                                                                </div>
                                                                <div className="col-md-12 get-quotes-notes">
                                                                    <Notes notesData={this.state.notesData} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                },
                                                // {
                                                //     title: "Options Chain", component: <> </>
                                                // },
                                                // {
                                                //     title: "Historical Data", 
                                                //     component: <HistDataTable 
                                                //     api={this.state.histApi} fromDate={this.state.fromdate} toDate={this.state.todate}
                                                //     filename={this.state.filename} 
                                                //     />
                                                // },
                                                ]} />
                                            {/* </div>
                                            </div> */}
                                        </div>
                                    </>
                            },]} containsAdditionTab={true}
                            addToTabs={this.state.recentTabs}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default GetQuotes;
